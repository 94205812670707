import { useForm } from '@mantine/form';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Flex,
  Image,
  Paper,
  Container,
  Group,
  Button,
  rem,
} from '@mantine/core';
import { useAuth } from 'hooks/useAuth';
import { useAppStore } from 'stores/appStore';

import ubiquiaLogo from 'assets/ubiquia_logo.svg';

function Login() {
  const { login } = useAuth();
  const navigate = useNavigate({ from: '/login' });
  const setUserEmail = useAppStore((state) => state.setUserEmail);

  const handleSubmit = async (values: { email: string; password: string }) => {
    // Clear the user email in case the user did not log out fully.
    // It will be set when hitting an authenticated route.
    setUserEmail('');

    await login(values.email, values.password);
    navigate({ to: '/' });
  };

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) => (value.length > 3 ? null : 'Password must be at least 4 characters'),
    },
  });

  return (
    <Container size={420} my={40}>
      <Flex justify="center">
        <Image src={ubiquiaLogo} alt="Ubiquia" h={rem(60)} w="auto" fit="contain" />
      </Flex>
      <Paper withBorder shadow="md" p={30} mt={15} radius="md">
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInput label="Email" placeholder="your@email.com" {...form.getInputProps('email')} />
          <PasswordInput label="Password" {...form.getInputProps('password')} mt="md" />
          <Group justify="space-between" mt="lg" style={{ display: 'none' }}>
            <Checkbox label="Remember me" />
            <Anchor component="button" size="sm">
              Forgot password?
            </Anchor>
          </Group>
          <Button type="submit" fullWidth mt="xl">
            Sign in
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export const Route = createFileRoute('/login')({
  component: Login,
});
