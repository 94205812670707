import {
  CalendarEventIndex,
  CalendarEventIndexRaw,
  CalendarMatchupFull,
  FullCalendarEventIndexRaw,
  FullScheduleMetricsEntry,
  FullScheduleMetricsEntryRaw,
  SCHEDULE_INDEX_MAP,
  TeamMetricsEntry,
  TeamType,
} from './scheduleConsts';

export const getScheduleJson: (
  id: string,
  team: TeamType
) => Promise<CalendarEventIndexRaw> = async (id: string, team: TeamType) => {
  try {
    const { default: data }: { default: CalendarEventIndexRaw } = await import(
      `../data/schedules/${id}/json/${team}_schedule.json`
    );
    return data;
  } catch (e) {
    // No schedule found for this team.
    return {};
  }
};

export const getFullScheduleJson: (id: string) => Promise<FullCalendarEventIndexRaw> = async (
  id: string
) => {
  const { default: data }: { default: FullCalendarEventIndexRaw } = await import(
    `../data/schedules/${id}/json/full_schedule.json`
  );
  return data;
};

export const getFullSchedule: (id: string) => Promise<CalendarMatchupFull[]> = async (
  id: string
) => {
  const raw = await getFullScheduleJson(id);
  const entries: CalendarMatchupFull[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [date, matchups] of Object.entries(raw)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const matchup of matchups) {
      entries.push({
        date,
        ...matchup,
      });
    }
  }
  return entries;
};

export const getTeamScheduleMetrics: (
  id: string,
  teamName: TeamType
) => Promise<TeamMetricsEntry> = async (id: string, teamName: TeamType) => {
  try {
    const { default: data }: { default: TeamMetricsEntry } = await import(
      `../data/schedules/${id}/json/${teamName}_schedule_metrics.json`
    );
    return data;
  } catch (e) {
    // No schedule metrics found for this team.
    return {
      viewership_total: 0,
      travel_distance_in_miles_total: 0,
      longest_home_stand_length: 0,
      longest_road_trip_length: 0,
      back_to_back_match_count: 0,
      three_matches_in_five_days_count: 0,
      border_crossings_count: 0,
    };
  }
};

export const getFullScheduleMetrics: (id: string) => Promise<FullScheduleMetricsEntry> = async (
  id: string
) => {
  try {
    const { default: data }: { default: FullScheduleMetricsEntryRaw } = await import(
      `../data/schedules/${id}/json/full_schedule_metrics.json`
    );
    return {
      id,
      // @ts-ignore
      name: SCHEDULE_INDEX_MAP[id] || 'Unknown',
      ...data,
    };
  } catch (e) {
    // No schedule metrics found for this team.
    return {
      id: '-1',
      name: 'N/A',
      viewership_total: 0,
      travel_distance_in_miles_average: 0,
      travel_distance_in_miles_total: 0,
      longest_home_stands_length: 0,
      longest_road_trip_length: 0,
      back_to_back_match_count: 0,
      three_matches_in_five_days_count: 0,
      us_team_border_crossings_border_crossings_count: 0,
      canadian_team_border_crossings_border_crossings_count: 0,
    };
  }
};

export const getImportedIframeUrl: (targetUrl: string) => Promise<string> = async (
  targetUrl: string
) => {
  const resUrl = new URL(`../data/${targetUrl}`, import.meta.url).href;
  return resUrl;
};

export const processCalendarIndexRaw: (
  rawIndex: CalendarEventIndexRaw,
  selectedTeam: TeamType
) => CalendarEventIndex = (rawIndex: CalendarEventIndexRaw, selectedTeam: TeamType) => {
  const index: CalendarEventIndex = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [date, matchup] of Object.entries(rawIndex)) {
    if (matchup.home === selectedTeam) {
      index.push({
        title: `${matchup.away}`,
        date,
        eastern_time: matchup.eastern_time,
        className: ['event', 'home-event'],
        textColor: 'black',
      });
    } else {
      index.push({
        title: `@${matchup.home}`,
        date,
        eastern_time: matchup.eastern_time,
        className: ['event', 'away-event'],
        textColor: 'black',
      });
    }
  }
  return index;
};
