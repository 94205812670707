import { ReactNode } from 'react';
import { Anchor, AppShell, Text, Group, Image, Stack, Tooltip, rem } from '@mantine/core';
import { Link } from '@tanstack/react-router';

import nhlLogo from 'assets/nhl_logo.png';
import ubiquiaLogo from 'assets/ubiquia_logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faArrowUpWideShort,
  faHouse,
  faRightFromBracket,
} from '@fortawesome/sharp-regular-svg-icons';
import { useAppStore } from 'stores/appStore';
import classes from './UbiquiaAppShell.module.css';

interface UbiquiaAppShellProps {
  children?: ReactNode;
}

interface NavbarLinkProps {
  iconDef: IconDefinition;
  label: string;
  route: string;
  search?: (prev: Record<string, string>) => Record<string, string>;
}

function NavbarLink({ iconDef, label, route, search }: NavbarLinkProps) {
  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <Link
        to={route}
        className={classes.link}
        search={search}
        activeOptions={{ includeSearch: false }}
      >
        <FontAwesomeIcon style={{ width: rem(20), height: rem(20) }} icon={iconDef} />
      </Link>
    </Tooltip>
  );
}

const linkData = [
  {
    iconDef: faHouse,
    label: 'Home',
    route: '/schedules',
    search: (prev: Record<string, string>) => ({ compare: prev.compare }),
  },
  {
    iconDef: faArrowUpWideShort,
    label: 'Compare',
    route: '/compare',
    search: (prev: Record<string, string>) => ({ compare: prev.compare }),
  },
];

export function UbiquiaAppShell({ children }: UbiquiaAppShellProps) {
  const links = linkData.map((link, index) => <NavbarLink {...link} key={index} />);
  const userEmail = useAppStore((state) => state.userEmail);

  return (
    <AppShell header={{ height: rem(40) }} navbar={{ width: 48, breakpoint: 0 }} padding={0}>
      <AppShell.Header>
        <Group h="100%" px="0" style={{ justifyContent: 'space-between' }} mx={rem(12)}>
          <Image src={ubiquiaLogo} alt="Ubiquia" h={rem(30)} w="auto" fit="contain" />
          <Image src={nhlLogo} alt="NFL" h={rem(34)} w="auto" fit="contain" />
        </Group>
      </AppShell.Header>
      <AppShell.Navbar px={rem(5)} py={rem(10)}>
        <Stack justify="center" gap="md">
          {links}
        </Stack>
        <Stack justify="center" gap={0} mt="auto">
          <NavbarLink iconDef={faRightFromBracket} label={`Logout ${userEmail}`} route="/logout" />
        </Stack>
      </AppShell.Navbar>
      <AppShell.Main>{children}</AppShell.Main>
      <AppShell.Footer>
        <Group justify="center" align="center" gap="0" mb="0px">
          <Text pr="8px">© 2012 – 2024</Text>
          <Anchor
            href="https://www.recentiveanalytics.com/"
            target="_blank"
            pb="5px"
            style={{ lineHeight: 1 }}
          >
            Recentive Analytics<sup>®</sup>
          </Anchor>
          , <Text pl="8px">All Rights Reserved.</Text>
        </Group>
      </AppShell.Footer>
    </AppShell>
  );
}
